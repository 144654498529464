import React from "react";

const UploadModal = ({
  handleImageChange,
  handleFolderChange,
  handleOpenCamera,
  addNewEmptylot,
  lotBySeparator,
  togglelotingMethod,
  lotSize,
  handlelotSizeChange,
  initializelots,
  handleUpload,
  loading,
  lots,
  downloadJSON,
  results,
}) => {
  return (
    <div className="space-y-2 bg-white p-5 rounded-[10px]">
      {" "}
      <div className="flex flex-col md:flex-row md:justify-between w-full items-center space-y-4 md:space-y-0">
        {/* Individual Images Upload */}
        <div className="flex flex-col space-y-2 w-full md:w-[20%]">
          <label className="block text-gray-700 font-semibold">
            Select Individual Images:
          </label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="p-2 border rounded w-full"
          />
        </div>

        {/* Folder Upload */}
        <div className="flex flex-col space-y-2 w-full md:w-[20%]">
          <label className="block text-gray-700 font-semibold">
            Select Folder:
          </label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFolderChange}
            className="p-2 border rounded w-full"
            webkitdirectory=""
          />
        </div>

        {/* Camera Upload */}
        <div className="flex flex-col space-y-2 w-full md:w-[15%]">
          <label className="block text-gray-700 font-semibold">
            Upload from Camera:
          </label>
          <button
            onClick={() => handleOpenCamera(null)}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition disabled:opacity-50 w-full mt-5"
          >
            Use Camera
          </button>
        </div>

        {/* Add New lot */}
        <div className="flex flex-col space-y-2 w-full md:w-[15%]">
          <label className="block text-gray-700 font-semibold">
            Create from Scratch:
          </label>
          <button
            onClick={addNewEmptylot}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition disabled:opacity-50 w-full mt-5"
          >
            Add New lot
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <label className="block text-gray-700 font-semibold">
          loting Method:
        </label>
        <div className="flex items-center space-x-4">
          <label>
            <input
              type="radio"
              name="lotingMethod"
              checked={!lotBySeparator}
              onChange={() => togglelotingMethod(false)}
            />{" "}
            By Size
          </label>
          <label>
            <input
              type="radio"
              name="lotingMethod"
              checked={lotBySeparator}
              onChange={() => togglelotingMethod(true)}
            />{" "}
            By Separator
          </label>
        </div>
      </div>
      {!lotBySeparator && (
        <div className="flex flex-row space-x-2 w-full">
          <input
            type="number"
            min="1"
            value={lotSize}
            onChange={handlelotSizeChange}
            className="p-2 border rounded w-[20%]"
          />
          <button
            onClick={initializelots}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Set lot Size
          </button>
        </div>
      )}
      <div className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0 w-full">
        <button
          onClick={handleUpload}
          disabled={loading || !lots.length}
          className="flex-1 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition disabled:opacity-50"
        >
          {loading ? "Processing..." : "Upload and Analyze"}
        </button>
        <button
          onClick={downloadJSON}
          disabled={!results.length}
          className="flex-1 px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition disabled:opacity-50"
        >
          Download Results JSON
        </button>
      </div>
    </div>
  );
};

export default UploadModal;
