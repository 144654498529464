import React, { useState, useEffect } from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
const ImageAnalysisForm = () => {
  const [podTitle, setPodTitle] = useState("");
  const [initialLotNumber, setInitialLotNumber] = useState("");
  const [userId] = useState(localStorage.getItem("userId")); // Replace with actual user ID logic
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewRequests, setViewRequests] = useState(false);
  const [requests, setRequests] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!podTitle || !initialLotNumber || files.length === 0) {
      alert("Please fill out all fields and upload at least one image.");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("podTitle", podTitle);
      formData.append("initialLotNumber", initialLotNumber);
      formData.append("userId", userId);

      for (const file of files) {
        formData.append("files", file);
      }

      const response = await axios.post(
        "http://devbackend.listornot.com/create-image-analysis-request",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert(
        "Request submitted successfully. Request ID: " + response.data.requestId
      );
    } catch (error) {
      console.error("Error creating image analysis request:", error.message);
      alert("Failed to create image analysis request.");
    } finally {
      setLoading(false);
    }
  };

  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        "https://devbackend.listornot.com/get-image-analysis-requests",
        {
          params: { userId },
        }
      );
      setRequests(response.data.data || []);
    } catch (error) {
      console.error("Error fetching requests:", error.message);
      alert("Failed to fetch requests.");
    }
  };

  const downloadAllPhotos = async (folderUrl, podTitle, fileNames) => {
    const JSZip = require("jszip");
    const { saveAs } = require("file-saver");
    const zip = new JSZip();

    // Create a folder inside the ZIP with the pod title
    const folder = zip.folder(podTitle);

    for (const fileName of fileNames) {
      try {
        // Use fetch directly with the full path
        const fileBlob = await fetch(`${folderUrl}${fileName}`).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch file: ${fileName}`);
          }
          return res.blob();
        });

        // Add the file to the ZIP folder
        folder.file(fileName, fileBlob);
      } catch (error) {
        console.error(`Error fetching file ${fileName}:`, error.message);
      }
    }

    // Generate the ZIP and trigger download
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${podTitle}.zip`);
    alert("All photos downloaded successfully.");
  };

  useEffect(() => {
    if (viewRequests) {
      fetchRequests();
    }
  }, [viewRequests]);

  return (
    <div className="p-4 bg-white rounded shadow-md space-y-4">
      {!viewRequests ? (
        <>
          <h2 className="text-xl font-semibold text-gray-700">
            Image Analysis Request
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block font-medium text-gray-700">
                POD Title
              </label>
              <input
                type="text"
                value={podTitle}
                onChange={(e) => setPodTitle(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Initial Lot Number
              </label>
              <input
                type="text"
                value={initialLotNumber}
                onChange={(e) => setInitialLotNumber(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Upload Images
              </label>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 text-white rounded ${
                loading ? "bg-gray-500" : "bg-blue-500"
              }`}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
          <button
            onClick={() => setViewRequests(true)}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
          >
            View Requests
          </button>
        </>
      ) : (
        <>
          <h2 className="text-xl font-semibold text-gray-700">Your Requests</h2>
          {requests.length > 0 ? (
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2">
                    POD Title
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Lot Number
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Folder URL
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id} className="hover:bg-gray-50">
                    <td className="border border-gray-300 px-4 py-2">
                      {request.podTitle}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {request.initialLotNumber}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <a
                        href={request.folderUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline"
                      >
                        {request.folderUrl}
                      </a>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        onClick={() =>
                          downloadAllPhotos(
                            request.folderUrl,
                            request.podTitle,
                            request.uploadedFileNames
                          )
                        }
                        className="px-2 py-1 bg-blue-500 text-white rounded"
                      >
                        Download All
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500">No requests found.</p>
          )}
          <button
            onClick={() => setViewRequests(false)}
            className="mt-4 px-4 py-2 bg-gray-500 text-white rounded"
          >
            Back to Form
          </button>
        </>
      )}
    </div>
  );
};

export default ImageAnalysisForm;
