import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import logo from "./assets/logo.png";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = ({ setSelectedComponent }) => {
  const [selected, setSelected] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // State for burger menu
  const navigate = useNavigate();

  const handleSelect = (component) => {
    setSelected(component);
    setSelectedComponent(component);
    setAnchorEl(null); // Close the menu after selection
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Toolbar className="flex justify-between">
        {/* Logo */}
        <Typography variant="h6" className="flex items-center text-black">
          <img src={logo} alt="Logo" className="w-10 h-10 rounded-full mr-2" />
          Listalot
        </Typography>

        {/* Desktop Buttons */}
        <div className="hidden md:flex space-x-4">
          <Button
            onClick={() => handleSelect("CSVUploader")}
            color={selected === "CSVUploader" ? "primary" : "inherit"}
          >
            CSV Uploader
          </Button>
          <Button
            onClick={() => handleSelect("ImageUploader")}
            color={selected === "ImageUploader" ? "primary" : "inherit"}
          >
            Image Uploader
          </Button>
          <Button
            onClick={() => handleSelect("BarcodeLookup")}
            color={selected === "BarcodeLookup" ? "primary" : "inherit"}
          >
            Barcode Lookup
          </Button>
          <Button
            onClick={() => handleSelect("ImageAnalysisRequest")}
            color={selected === "ImageAnalysisRequest" ? "primary" : "inherit"}
          >
            Image Analysis Request
          </Button>
          <Button onClick={handleLogout} variant="contained" color="error">
            Logout
          </Button>
        </div>

        {/* Mobile Burger Menu */}
        <div className="md:hidden">
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => handleSelect("CSVUploader")}
              selected={selected === "CSVUploader"}
            >
              CSV Uploader
            </MenuItem>
            <MenuItem
              onClick={() => handleSelect("ImageUploader")}
              selected={selected === "ImageUploader"}
            >
              Image Uploader
            </MenuItem>
            <MenuItem
              onClick={() => handleSelect("BarcodeLookup")}
              selected={selected === "BarcodeLookup"}
            >
              Barcode Lookup
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
