import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./firebase"; 
import LandingPage from "./LandingPage";
import Navbar from "./Navbar";
import ImageUploader from "./ImageUploader";
import BarcodeLookup from "./BarcodeLookup";
import CSVUploader from "./CSVUploader";
import ImageAnalysisForm from "./ImageAnslysisForm";

const App = () => {
  const [user, setUser] = useState(null); // User state for authentication

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
    
      } else {
        setUser(null); // Clear user if not authenticated
      }
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  return (
    <Router>
      <Routes>
        {/* Public Route: Landing Page */}
        <Route
          path="/"
          element={
            user ? <Navigate to="/dashboard" /> : <LandingPage />
          }
        />

        {/* Protected Route: Dashboard */}
        <Route
          path="/dashboard"
          element={
            user ? (
              <Dashboard />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

// Dashboard Component
const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState("CSVUploader");

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      <Navbar setSelectedComponent={setSelectedComponent} />
      <div className="flex justify-center p-4 md:p-8">
        {selectedComponent === "CSVUploader" && <CSVUploader />}
        {selectedComponent === "ImageUploader" && <ImageUploader />}
        {selectedComponent === "BarcodeLookup" && <BarcodeLookup />}
        {selectedComponent === "ImageAnalysisRequest" && <ImageAnalysisForm />}
      </div>
    </div>
  );
};

export default App;