import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase"; // Firebase auth
import axios from "axios"; // Axios for API calls

const LandingPage = () => {
  const [showLogin, setShowLogin] = useState(true);

  return (
    <div className="flex w-screen h-screen bg-gradient-to-r from-purple-600 to-blue-500 text-gray-800 flex-col md:flex-row">
      {/* Left side: Information about Listalot */}
      <div className="hidden md:flex flex-col justify-center items-center w-1/2 h-full space-y-8 bg-white">
        <h1 className="text-5xl font-bold text-primary">Welcome to Listalot</h1>
        <p className="text-lg text-gray-600 leading-relaxed text-center max-w-md">
          Listalot is your go-to platform for efficient barcode lookups, CSV
          processing, and image uploads. Start by signing up or logging in to
          explore our features!
        </p>
      </div>

      {/* Vertical separator line */}
      <div className="hidden md:block w-0.5 h-full bg-gray-300"></div>

      {/* Right side: Login or Signup form */}
      <div className="flex flex-col justify-center items-center w-full md:w-1/2 h-full bg-primary px-4">
        <div className="w-full max-w-sm bg-white p-8 rounded-lg shadow-lg">
          <div className="flex justify-center space-x-4 mb-6">
            <button
              className={`w-1/2 px-4 py-2 rounded-full font-semibold transition ${
                showLogin
                  ? "bg-bcolor text-white shadow-md"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setShowLogin(true)}
            >
              Login
            </button>
            <button
              className={`w-1/2 px-4 py-2 rounded-full font-semibold transition ${
                !showLogin
                  ? "bg-bcolor text-white shadow-md"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setShowLogin(false)}
            >
              Signup
            </button>
          </div>
          <div className="transition-all duration-500 ease-in-out transform">
            {showLogin ? <StyledLogin /> : <StyledSignup />}
          </div>
        </div>
      </div>
    </div>
  );
};

// Styled Login Component
const StyledLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken();

      const response = await axios.post(
        "https://devbackend.listornot.com/login",
        {
          email,
          idToken,
        }
      );

      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("username", response.data.user.username);
      setError("");
    } catch (err) {
      console.error("Login error:", err);
      setError("Failed to log in. Please check your credentials.");
    }
  };

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleLogin}>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your email"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">
          Password
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your password"
        />
      </div>
      {error && <p className="text-red-600 text-sm">{error}</p>}
      <button
        type="submit"
        className="w-full px-4 py-2 mt-4 bg-bcolor text-white rounded-full font-semibold hover:bg-blue-700 transition"
      >
        Login
      </button>
    </form>
  );
};

// Styled Signup Component
const StyledSignup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!email || !password || !name) {
      setError("All fields are required.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user.uid;

      const response = await axios.post(
        "https://devbackend.listornot.com/signup",
        {
          email,
          username: name,
          userId: user,
        }
      );

      localStorage.setItem("userId", user);
      localStorage.setItem("email", email);
      localStorage.setItem("username", name);
      setError("");
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("This email is already registered. Please log in instead.");
      } else {
        setError("Failed to sign up. Please check your details.");
      }
      console.error("Signup error:", err.message);
    }
  };

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSignup}>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">
          Full Name
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your full name"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your email"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">
          Password
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Create a password"
        />
      </div>
      {error && <p className="text-red-600 text-sm">{error}</p>}
      <button
        type="submit"
        className="w-full px-4 py-2 mt-4 bg-bcolor text-white rounded-full font-semibold hover:bg-blue-700 transition"
      >
        Signup
      </button>
    </form>
  );
};

export default LandingPage;
