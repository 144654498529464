import React, { useState, useEffect } from "react";
import axios from "axios";

const BarcodeLookup = () => {
  const [result, setResult] = useState(null);

  const [pastLookups, setPastLookups] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userId] = useState(localStorage.getItem("userId")); // Replace with actual user ID

  // Fetch past barcode lookups
  useEffect(() => {
    const fetchPastLookups = async () => {
      try {
        const response = await axios.get(
          "https://devbackend.listornot.com/lookup-barcode",
          {
            params: { userId },
          }
        );
        setPastLookups(response.data.data || []);
      } catch (error) {
        console.error("Error fetching past lookups:", error.message);
      }
    };

    fetchPastLookups();
  }, [userId]);

  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleCsvUpload = async () => {
    if (!csvFile) return alert("Please select a CSV file to upload");

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", csvFile);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://devbackend.listornot.com/process-csv-barcode",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert(
        "CSV processed successfully! Download: " + response.data.downloadUrl
      );
      setCsvFile(null);
    } catch (error) {
      console.error("Error processing CSV:", error.message);
      alert("Failed to process CSV");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="p-4 md:p-8 bg-white rounded-lg shadow-lg space-y-6 max-w-4xl mx-auto">
      <h2 className="text-xl font-semibold text-gray-700">Barcode Lookup</h2>
      <div className="flex flex-col items-center space-y-4">
        <h3 className="text-lg font-medium text-gray-700">
          Bulk Barcode Lookup
        </h3>
        <input
          type="file"
          accept=".csv"
          onChange={handleCsvChange}
          className="p-2 border rounded w-full max-w-md"
        />
        <button
          onClick={handleCsvUpload}
          disabled={uploading}
          className={`px-4 py-2 ${
            uploading ? "bg-gray-500" : "bg-purple-500"
          } text-white rounded hover:bg-purple-600 transition disabled:opacity-50`}
        >
          {uploading ? "Uploading..." : "Upload CSV"}
        </button>
      </div>
      {/* Past Lookups Table */}
      <div>
        <h3 className="text-lg font-medium text-gray-700">
          Past Barcode Lookups
        </h3>
        {pastLookups.length > 0 ? (
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">File Name</th>

                <th className="border border-gray-300 px-4 py-2">Download</th>
              </tr>
            </thead>
            <tbody>
              {pastLookups.map((lookup) => (
                <tr key={lookup.id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">
                    {lookup.fileName}
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    <a
                      href={lookup.downloadUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500">No past lookups found.</p>
        )}
      </div>

      {/* Barcode Lookup */}
      <div className="flex flex-col items-center space-y-4">
        {result && (
          <div className="mt-6 w-full">
            <h3 className="text-lg font-medium text-gray-700">
              Lookup Results
            </h3>
            {result.map((item, index) => (
              <div
                key={index}
                className="p-4 mt-4 bg-gray-100 rounded-lg shadow-md"
              >
                <h4 className="text-md font-semibold">{item.title}</h4>
                <p>
                  <strong>Category:</strong> {item.category}
                </p>
                <p>
                  <strong>Brand:</strong> {item.brand || "Unknown"}
                </p>
                <p>
                  <strong>Size:</strong> {item.size || "N/A"}
                </p>
                <p>
                  <strong>Description:</strong> {item.description}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Bulk CSV Upload */}
    </div>
  );
};

export default BarcodeLookup;
