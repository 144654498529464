import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Skeleton, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Webcam from "react-webcam";

const CameraModal = ({
  open,
  onClose,
  title,
  setImages,
  setOriginalPreviews,
  targetlotIndex,
  lots,
  setlots,
  setlotDescriptions,
  setImageOptions,
}) => {
  const webcamRef = React.useRef(null);
  const [capturedPreviews, setCapturedPreviews] = useState([]);
  const [loading, setLoading] = useState(true);

  // Preload audio files
  const captureAudio = React.useRef(
    new Audio("https://storage.cloud.google.com/listalotimages/capture.mp3")
  );
  const blankAudio = React.useRef(
    new Audio("https://storage.googleapis.com/listalotimages/nextitem.mp3")
  );
  const doneAudio = React.useRef(
    new Audio("https://storage.cloud.google.com/listalotimages/done.mp3")
  );

  useEffect(() => {
    if (!open) {
      setCapturedPreviews([]);
      setLoading(true); // Reset loading state when the modal is closed
    } else {
      // Simulate a loading delay when the modal opens
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const playAudio = (audioRef) => {
    audioRef.current
      .play()
      .catch((error) => console.error("Audio playback failed:", error));
  };

  const handleCapture = () => {
    playAudio(captureAudio); // Play capture sound
    const imageSrc = webcamRef.current.getScreenshot(); // Get the captured image as base64
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob()) // Convert base64 to Blob
        .then((blob) => {
          const file = new File([blob], `captured_image_${Date.now()}.jpg`, {
            type: "image/jpeg",
          });
          const preview = URL.createObjectURL(blob);
          setImages((prev) => [...prev, file]);
          setOriginalPreviews((prev) => [...prev, preview]);

          if (targetlotIndex !== null) {
            const updatedlots = [...lots];
            updatedlots[targetlotIndex] = [
              ...updatedlots[targetlotIndex],
              file,
            ];
            setlots(updatedlots);
          } else {
            setlots((prevlots) => [...prevlots, [file]]);
            setlotDescriptions((prevDescriptions) => [...prevDescriptions, ""]);
            setImageOptions((prevOptions) => [
              ...prevOptions,
              [{ option: "original" }],
            ]);
          }
          setCapturedPreviews((prev) => [...prev, preview]);
        })
        .catch((error) => console.error("Error processing capture:", error));
    }
  };
  const handleRemoveImage = (index) => {
    setCapturedPreviews((prev) => prev.filter((_, i) => i !== index));
    setImages((prev) => prev.filter((_, i) => i !== index));
    setOriginalPreviews((prev) => prev.filter((_, i) => i !== index));
    if (targetlotIndex !== null) {
      const updatedlots = [...lots];
      updatedlots[targetlotIndex] = updatedlots[targetlotIndex].filter(
        (_, i) => i !== index
      );
      setlots(updatedlots);
    } else {
      setlots((prevlots) =>
        prevlots.map((lot) => lot.filter((_, i) => i !== index))
      );
    }
  };
  const handleAddBlankImage = () => {
    playAudio(blankAudio); // Play blank image sound
    const canvas = document.createElement("canvas");
    canvas.width = 1280;
    canvas.height = 720;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      const file = new File([blob], `blank_image_${Date.now()}.jpg`, {
        type: "image/jpeg",
      });
      const preview = URL.createObjectURL(blob);

      setImages((prev) => [...prev, file]);
      setOriginalPreviews((prev) => [...prev, preview]);

      if (targetlotIndex !== null) {
        const updatedlots = [...lots];
        updatedlots[targetlotIndex] = [...updatedlots[targetlotIndex], file];
        setlots(updatedlots);
      } else {
        setlots((prevlots) => [...prevlots, [file]]);
        setlotDescriptions((prevDescriptions) => [...prevDescriptions, ""]);
        setImageOptions((prevOptions) => [
          ...prevOptions,
          [{ option: "original" }],
        ]);
      }
      setCapturedPreviews((prev) => [...prev, preview]);
    });
  };

  const handleDone = () => {
    playAudio(doneAudio); // Play done sound
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxHeight: "90%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
          overflowY: "auto",
        }}
      >
        {/* Camera Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRight: { xs: "none", md: "1px solid #ddd" },
            borderBottom: { xs: "1px solid #ddd", md: "none" },
            p: 2,
          }}
        >
    
          <div className="flex justify-end space-x-4 mt-4 mb-2">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCapture}
              disabled={loading}
            >
              Capture
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddBlankImage}
              disabled={loading}
            >
              Next Item
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDone}>
              Done
            </Button>
          </div>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              sx={{ borderRadius: "8px" }}
            />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: "environment",
              }}
              className="w-full h-auto rounded-md shadow-md"
            />
          )}
 
        </Box>

        {/* Captured Previews Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            p: 2,
            overflowY: { xs: "none", md: "auto" },
          }}
        >
          <h3 className="text-lg font-semibold mb-4 text-center">
            Captured Images
          </h3>
          <div className="grid grid-cols-2 gap-4 w-full">
            {capturedPreviews.map((preview, index) => (
              <div key={index} className="relative flex flex-col items-center">
                <img
                  src={preview}
                  alt={`Captured ${index}`}
                  className="w-full h-auto rounded-md shadow-md"
                />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-2 right-2 bg-white shadow-md rounded-full"
                >
                  <Delete />
                </IconButton>
              </div>
            ))}
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default CameraModal;
