import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import { Box, Modal, Button, TextField } from "@mui/material";

import LotDisplay from "./LotDisplay";
import PreviousAnalysesTable from "./PreviousAnalysesTable";
import CameraModal from "./CameraModal";
import UploadModal from "./UploadModal";
const ImageUploader = () => {
  const [images, setImages] = useState([]);
  const [lotDescriptions, setlotDescriptions] = useState([]);
  const [imageOptions, setImageOptions] = useState([]);
  const [originalPreviews, setOriginalPreviews] = useState([]);
  const [initialLotNumber, setInitialLotNumber] = useState(1);
  const [lotBySeparator, setlotBySeparator] = useState(false);
  const [results, setResults] = useState([]);
  const [pod, setpod] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [lotSize, setlotSize] = useState(1);
  const [lots, setlots] = useState([]);
  const [mergelotIndex, setMergelotIndex] = useState(null);
  const [targetlotIndex, setTargetlotIndex] = useState(null); // New state for selecting the target lot
  const [previousAnalyses, setPreviousAnalyses] = useState([]);

  const fetchPreviousAnalyses = useCallback(async () => {
    setLoading(true);
    const userId = localStorage.getItem("userId"); // Replace with your userId retrieval logic
    try {
      const response = await axios.get(
        `https://devbackend.listornot.com/get-user-analyses?userId=${userId}`
      );
      if (response.data && response.data.analyses) {
        console.log(response.data.analyses);
        setPreviousAnalyses(response.data.analyses);
      } else {
        console.warn("Unexpected response format:", response.data);
        setPreviousAnalyses([]);
      }
    } catch (error) {
      console.error("Error fetching previous analyses:", error.message);
      setPreviousAnalyses([]);
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array ensures the function is memoized

  useEffect(() => {
    fetchPreviousAnalyses();
  }, [fetchPreviousAnalyses]); // Add fetchPreviousAnalyses as a dependency
  const lotRefs = useRef([]);
  const handlelotSizeChange = (e) => setlotSize(Number(e.target.value));
  const handleOpenCamera = (lotIndex) => {
    setTargetlotIndex(lotIndex);
    setIsCameraOpen(true);
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
    setTargetlotIndex(null);
  };

  // Automatically initialize lots when loting method or lot size changes
  useEffect(() => {
    const timeout = setTimeout(() => {
      initializelots();
    }, 300); // Debounce for 300ms
    return () => clearTimeout(timeout);
  }, [lotBySeparator]);

  const addNewEmptylot = () => {
    setlots((prevlots) => {
      const newlots = [...prevlots, []]; // Add new empty lot
      lotRefs.current.push(React.createRef()); // Add ref for the new lot
      return newlots;
    });
    setlotDescriptions((prevDescriptions) => [...prevDescriptions, ""]);
    setImageOptions((prevOptions) => [
      ...prevOptions,
      [{ option: "original" }],
    ]);
  };
  useEffect(() => {
    if (lots.length > 0) {
      const lastlotRef = lotRefs.current[lots.length - 1];
      if (lastlotRef && lastlotRef.current) {
        lastlotRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the lot
        });
      }
    }
  }, [lots]);

  const initializelots = async () => {
    setLoading(true);
    try {
      const allImages = [...images]; // Use all images from the state, including captured ones

      if (lotBySeparator) {
        // lot by separator logic
        const lotedBySeparator = await lotImagesBySeparator(allImages);

        if (JSON.stringify(lots) !== JSON.stringify(lotedBySeparator)) {
          setlots(lotedBySeparator);
          setlotDescriptions(lotedBySeparator.map(() => ""));
          setImageOptions(
            lotedBySeparator.map((lot) =>
              lot.map(() => ({ option: "original" }))
            )
          );
        }
      } else {
        // lot by size logic
        const initiallots = [];
        const initialDescriptions = [];
        const initialOptions = [];

        for (let i = 0; i < allImages.length; i += lotSize) {
          initiallots.push(allImages.slice(i, i + lotSize));
          initialDescriptions.push("");
          initialOptions.push(
            Array(allImages.slice(i, i + lotSize).length).fill({
              option: "original",
            })
          );
        }

        if (JSON.stringify(lots) !== JSON.stringify(initiallots)) {
          setlots(initiallots);
          setlotDescriptions(initialDescriptions);
          setImageOptions(initialOptions);
        }
      }
    } catch (error) {
      console.error("Error initializing lots:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleInitialLotNumberChange = (e) => {
    setInitialLotNumber(Number(e.target.value));
  };

  const togglelotingMethod = (bySeparator) => {
    setlotBySeparator(bySeparator);
    initializelots();
  };
  const addImagesTolots = (newImages) => {
    const newlots = newImages.map((image) => [image]);
    const newDescriptions = newImages.map(() => "");
    const newOptions = newImages.map(() => [{ option: "original" }]);

    setlots((prevlots) => [...prevlots, ...newlots]);
    setlotDescriptions((prev) => [...prev, ...newDescriptions]);
    setImageOptions((prev) => [...prev, ...newOptions]);
  };

  const downloadJSON = () => {
    if (!results.length) return;

    const jsonBlob = new Blob([JSON.stringify(results, null, 2)], {
      type: "application/json",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(jsonBlob);
    link.download = "results.json";
    link.click();
  };
  const splitlot = (lotIndex, splitIndex) => {
    const updatedlots = [...lots];
    const lotToSplit = updatedlots[lotIndex];

    // Validate split index
    if (splitIndex <= 0 || splitIndex >= lotToSplit.length) {
      alert("Invalid split point. Please select a valid image.");
      return;
    }

    // Create two new lots from the split
    const firstPart = lotToSplit.slice(0, splitIndex);
    const secondPart = lotToSplit.slice(splitIndex);

    // Update lots by replacing the original with the two new lots
    updatedlots.splice(lotIndex, 1, firstPart, secondPart);

    // Duplicate descriptions and options for the new lots
    const updatedDescriptions = [...lotDescriptions];
    const updatedImageOptions = [...imageOptions];

    const originalDescription = updatedDescriptions[lotIndex] || "";
    const originalOptions = updatedImageOptions[lotIndex] || [];

    updatedDescriptions.splice(
      lotIndex,
      1,
      originalDescription,
      originalDescription
    );
    updatedImageOptions.splice(
      lotIndex,
      1,
      originalOptions.slice(0, splitIndex),
      originalOptions.slice(splitIndex)
    );

    // Update state
    setlots(updatedlots);
    setlotDescriptions(updatedDescriptions);
    setImageOptions(updatedImageOptions);
  };
  const checkJobStatus = async (userId, pod) => {
    try {
      const response = await axios.get(
        `https://devbackend.listornot.com/job-status?userId=${userId}&pod=${pod}`
      );

      if (response.data) {
        const { status, results } = response.data;
        console.log("Job Status:", status); // Log status for debugging
        setProcessingStatus(status);

        if (status === "completed") {
          setResults(results);
          setProgress(100);
          fetchPreviousAnalyses();
          setIsProcessing(false);
        } else if (status === "pending") {
          setProgress((prev) => Math.min(prev + 10, 95)); // Increment progress
        } else if (status === "failed") {
          setIsProcessing(false);
          console.error("Job failed.");
        }

        return status; // Return the job status
      }
    } catch (error) {
      console.error("Error fetching job status:", error.message);
      setProcessingStatus("failed");
      setIsProcessing(false);
      return "failed";
    }
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    resetUploadState(); // Reset all states related to upload
  };

  const resetUploadState = () => {
    setImages([]);
    setlots([]);
    setlotDescriptions([]);
    setImageOptions([]);
    setOriginalPreviews([]);
    setResults([]);
    setProgress(0);
    setIsProcessing(false);
    setpod(null);
    setProcessingStatus(null);
  };
  const pollingIntervalRef = useRef(null);
  const pollJobStatus = (userId, pod) => {
    // Clear any existing interval before starting a new one
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }

    pollingIntervalRef.current = setInterval(async () => {
      try {
        const response = await checkJobStatus(userId, pod);

        // Stop polling if processing is complete or failed
        if (response === "completed" || response === "failed") {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
      } catch (error) {
        console.error("Error polling job status:", error.message);
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    }, 3000);
  };
  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);
  const handleUpload = async () => {
    if (!lots.length) return;

    setLoading(true);
    setIsProcessing(true);
    setProgress(0);

    const formData = new FormData();

    const lotsData = lots.map((lot, lotIndex) => {
      const description = lotDescriptions[lotIndex];
      const optionsForlot = imageOptions[lotIndex];

      return {
        description,
        images: lot.map((_, imageIndex) => ({
          option: optionsForlot[imageIndex]?.option || "original",
        })),
      };
    });

    formData.append("lots", JSON.stringify(lotsData));
    formData.append("userId", localStorage.getItem("userId"));
    formData.append("initialLotNumber", initialLotNumber);
    lots.forEach((lot, lotIndex) => {
      lot.forEach((image, imageIndex) => {
        formData.append(`lot_${lotIndex}_image_${imageIndex}`, image);
      });
    });

    try {
      const response = await axios.post(
        "https://devbackend.listornot.com/process-images",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data && response.data.pod) {
        setpod(response.data.pod);
        fetchPreviousAnalyses();
        const userId = localStorage.getItem("userId");
        pollJobStatus(userId, response.data.pod);
      } else {
        console.warn("Unexpected response format:", response.data);
        setIsProcessing(false);
      }
      handleCloseUploadModal();
    } catch (error) {
      console.error("Error starting upload:", error.message);
      setIsProcessing(false);
    } finally {
      setLoading(false);
    }
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    addImagesTolots(files);
    setImages((prev) => [...prev, ...files]);
    setOriginalPreviews((prev) => [
      ...prev,
      ...files.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const handleFolderChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prev) => [...prev, ...files]);
    setOriginalPreviews((prev) => [
      ...prev,
      ...files.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const isSeparatorImage = async (imageFile) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = 50; // Small resolution for faster performance
        canvas.height = 50;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        let blackPixelCount = 0;

        // Check each pixel
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          const brightness = (r + g + b) / 3;

          // Consider a pixel black if brightness is very low
          if (brightness < 20) {
            blackPixelCount++;
          }
        }

        const totalPixels = data.length / 4;
        const blackRatio = blackPixelCount / totalPixels;

        // Image is a separator if more than 90% of pixels are black
        resolve(blackRatio > 0.9);
      };

      img.onerror = () => resolve(false);
    });
  };
  const lotImagesBySeparator = async (imageFiles) => {
    const lotedImages = [];
    let currentlot = [];

    for (const file of imageFiles) {
      const isSeparator = await isSeparatorImage(file);
      if (isSeparator) {
        // Add current lot to lotedImages if it contains images
        if (currentlot.length > 0) {
          lotedImages.push(currentlot);
          currentlot = [];
        }
      } else {
        currentlot.push(file);
      }
    }

    // Add the last lot if not empty
    if (currentlot.length > 0) {
      lotedImages.push(currentlot);
    }

    return lotedImages;
  };

  const handlelotChange = (currentlotIndex, imageIndex, newlotIndex) => {
    const updatedlots = [...lots];
    const [image] = updatedlots[currentlotIndex].splice(imageIndex, 1);

    // Remove the image from the original lot if it becomes empty
    if (updatedlots[currentlotIndex].length === 0) {
      updatedlots.splice(currentlotIndex, 1);
      setlotDescriptions((prev) =>
        prev.filter((_, i) => i !== currentlotIndex)
      );
    }

    // If the new lot index is greater than or equal to the current lot length, add a new lot
    if (newlotIndex >= updatedlots.length) {
      updatedlots.push([image]);
      setlotDescriptions((prev) => [...prev, ""]);
    } else {
      updatedlots[newlotIndex].push(image);
    }

    // Ensure the new lot has the default option "original" for the image
    const updatedImageOptions = [...imageOptions];
    if (!Array.isArray(updatedImageOptions[newlotIndex])) {
      updatedImageOptions[newlotIndex] = [];
    }

    // Add the new image with "original" as its default option
    updatedImageOptions[newlotIndex].push({ option: "original" });

    // Update the state with the new lots and image options
    setlots(updatedlots);
    setImageOptions(updatedImageOptions);
  };

  const handleRemoveImage = (lotIndex, imageIndex) => {
    const updatedlots = [...lots];
    updatedlots[lotIndex].splice(imageIndex, 1);

    if (updatedlots[lotIndex].length === 0) {
      updatedlots.splice(lotIndex, 1);
      setlotDescriptions((prev) => prev.filter((_, i) => i !== lotIndex));
    }

    setlots(updatedlots);
  };

  const deletelot = (lotIndex) => {
    const updatedlots = lots.filter((_, index) => index !== lotIndex);
    const updatedDescriptions = lotDescriptions.filter(
      (_, index) => index !== lotIndex
    );
    setlots(updatedlots);
    setlotDescriptions(updatedDescriptions);
  };

  const handleDescriptionChange = (index, value) => {
    setlotDescriptions((prev) =>
      prev.map((desc, i) => (i === index ? value : desc))
    );
  };

  const addImageTolot = (lotIndex, file) => {
    setlots((prevlots) => {
      const updatedlots = [...prevlots];
      updatedlots[lotIndex] = [...updatedlots[lotIndex], file]; // Append the new file
      return updatedlots;
    });

    setImageOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      if (!Array.isArray(updatedOptions[lotIndex])) {
        updatedOptions[lotIndex] = [];
      }
      updatedOptions[lotIndex].push({ option: "original" }); // Default to original
      return updatedOptions;
    });
  };

  const mergelots = (index1, index2) => {
    if (index1 !== index2 && index1 >= 0 && index2 >= 0) {
      const updatedlots = [...lots];
      updatedlots[index1] = [...updatedlots[index1], ...updatedlots[index2]];
      updatedlots.splice(index2, 1);

      const updatedDescriptions = [...lotDescriptions];
      updatedDescriptions.splice(index2, 1);
      setlots(updatedlots);
      setlotDescriptions(updatedDescriptions);
      setMergelotIndex(null);
      setTargetlotIndex(null);
    }
  };

  const handleOptionChange = (lotIndex, imageIndex, value) => {
    setImageOptions((prev) => {
      const newOptions = [...prev];

      // Ensure the lot exists and is an array
      if (!Array.isArray(newOptions[lotIndex])) {
        newOptions[lotIndex] = [];
      }

      newOptions[lotIndex][imageIndex] = { option: value };
      return newOptions;
    });
  };
  // Function to keep original for all images
  const keepOriginalForAll = () => {
    setImageOptions((prev) =>
      prev.map((lot) =>
        Array.isArray(lot) ? lot.map(() => ({ option: "original" })) : []
      )
    );
  };

  const keepProcessedForAll = () => {
    setImageOptions((prev) =>
      prev.map((lot) =>
        Array.isArray(lot) ? lot.map(() => ({ option: "processed" })) : []
      )
    );
  };

  return (
    <div className="w-full space-y-3">
      <div className="flex flex-row items-center justify-between p-4 md:p-8rounded-lg w-[100%] mx-auto">
        <h2 className="text-lg md:text-xl font-semibold text-gray-700">
          Image Upload Analysis
        </h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsUploadModalOpen(true)}
        >
          Add New Pod
        </Button>
      </div>
      <div className="p-4">
        <label className="block mb-2 text-sm font-medium text-gray-900">
          Initial Lot Number
        </label>
        <TextField
          type="number"
          value={initialLotNumber}
          onChange={handleInitialLotNumberChange}
          variant="outlined"
          fullWidth
        />
      </div>
      {loading ? (
        <div className="w-full mt-4">
          <Skeleton variant="rectangular" width="100%" height={200} />
        </div>
      ) : (
        <div className="w-full mt-4">
          {previousAnalyses.length > 0 ? (
            <PreviousAnalysesTable previousAnalyses={previousAnalyses} />
          ) : (
            <p className="text-gray-600">No previous analyses found.</p>
          )}
        </div>
      )}

      <Modal
        open={isUploadModalOpen}
        onClose={handleCloseUploadModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{ p: 4, maxWidth: "80%", maxHeight: "95%", overflowY: "auto" }}
        >
          <UploadModal
            handleImageChange={handleImageChange}
            handleFolderChange={handleFolderChange}
            handleOpenCamera={handleOpenCamera}
            addNewEmptylot={addNewEmptylot}
            togglelotingMethod={togglelotingMethod}
            lotBySeparator={lotBySeparator}
            setlotBySeparator={setlotBySeparator}
            lotSize={lotSize}
            handlelotSizeChange={handlelotSizeChange}
            initializelots={initializelots}
            handleUpload={handleUpload}
            loading={loading}
            lots={lots}
            downloadJSON={downloadJSON}
            results={results}
          />
          <div className="w-full mt-4">
            {lots.length > 0 && (
              <div className="mt-4">
                {lots.map((lot, index) => (
                  <LotDisplay
                    key={index}
                    lots={lots}
                    lot={lot}
                    initialLotNumber={initialLotNumber}
                    lotIndex={index}
                    lotRef={lotRefs.current[index]}
                    lotDescriptions={lotDescriptions}
                    imageOptions={imageOptions}
                    setlotDescriptions={setlotDescriptions}
                    keepOriginalForAll={keepOriginalForAll}
                    keepProcessedForAll={keepProcessedForAll}
                    handleDescriptionChange={handleDescriptionChange}
                    handleOptionChange={handleOptionChange}
                    handleRemoveImage={handleRemoveImage}
                    handlelotChange={handlelotChange}
                    addImageTolot={addImageTolot}
                    deletelot={deletelot}
                    splitlot={splitlot}
                    handleOpenCamera={() => handleOpenCamera(index)}
                    mergelotIndex={mergelotIndex}
                    setMergelotIndex={setMergelotIndex}
                    targetlotIndex={targetlotIndex}
                    setTargetlotIndex={setTargetlotIndex}
                    mergelots={mergelots}
                    setIsCameraOpen={setIsCameraOpen}
                  />
                ))}
              </div>
            )}
          </div>
          <CameraModal
            open={isCameraOpen}
            onClose={handleCloseCamera}
            title={
              targetlotIndex === null
                ? "Capture Global Image"
                : "Capture lot Image"
            }
            setImages={setImages} // Pass the setImages function
            setOriginalPreviews={setOriginalPreviews} // Pass the setOriginalPreviews function
            targetlotIndex={targetlotIndex} // Pass the targetlotIndex state
            lots={lots} // Pass the lots state
            setlots={setlots} // Pass the setlots function
            setlotDescriptions={setlotDescriptions} // Pass the setlotDescriptions function
            setImageOptions={setImageOptions} // Pass the setImageOptions function
          />
        </Box>
      </Modal>

      {isProcessing && (
        <div className="w-full mt-4">
          <h3 className="text-lg font-semibold">Processing Images...</h3>
          <LinearProgress variant="determinate" value={progress} />
          <p className="mt-2 text-gray-600">Please wait while processing...</p>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
