import React from "react";

const ResultsDisplay = ({ results, initialLotNumber }) => {
  if (results.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col items-center mt-6 space-y-6">
      <h2 className="text-lg md:text-xl font-medium">Image Analysis Results</h2>
      {results.map((result, lotIndex) => (
        <div
          key={lotIndex}
          className="space-y-4 text-center bg-white p-4 rounded-lg shadow-md w-full max-w-md"
        >
          <h4 className="mt-4">
            <strong>Images:</strong>
          </h4>
          <div className="grid grid-cols-2 gap-4 mt-2">
            {result.original && result.original.length > 0 && (
              <div>
                <h5 className="text-sm font-medium">Original Images</h5>
                {result.original.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Original ${index + 1}`}
                    className="w-40 h-auto rounded-md shadow-md mt-2"
                  />
                ))}
              </div>
            )}
            {result.no_background && result.no_background.length > 0 && (
              <div>
                <h5 className="text-sm font-medium">Processed Images</h5>
                {result.no_background.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Processed ${index + 1}`}
                    className="w-40 h-auto rounded-md shadow-md mt-2"
                  />
                ))}
              </div>
            )}
          </div>
          <h3 className="text-md font-semibold">
            lot {lotIndex + parseInt(initialLotNumber)}
          </h3>
          <p>
            <strong>Description:</strong> {result.lotDescription}
          </p>

          <div className="text-left mt-4">
            <ul className="list-none ml-0">
              <li>
                <strong>Title:</strong> {result.title}
              </li>
              <li>
                <strong>Description:</strong> {result.description}
              </li>
              <li>
                <strong>Price:</strong> {result.price}
              </li>
              <li>
                <strong>Category:</strong> {result.category}
              </li>
              <li>
                <strong>Brand:</strong> {result.brand}
              </li>
              <li>
                <strong>Model:</strong> {result.model}
              </li>
              <li>
                <strong>Manufacturer:</strong> {result.manufacturer}
              </li>
              <li>
                <strong>Color:</strong> {result.color}
              </li>
              <li>
                <strong>Size:</strong> {result.size}
              </li>
              <li>
                <strong>UPC:</strong> {result.upc}
              </li>
              <li>
                <strong>ASIN:</strong> {result.asin}
              </li>
              <li>
                <strong>Dimensions:</strong>
                <ul>
                  <li>
                    <strong>Width:</strong> {result.dimensions?.width}
                  </li>
                  <li>
                    <strong>Height:</strong> {result.dimensions?.height}
                  </li>
                  <li>
                    <strong>Length:</strong> {result.dimensions?.length}
                  </li>
                </ul>
              </li>
            </ul>
            <h4 className="mt-4">
              <strong>Specifications:</strong>
            </h4>
            <ul>
              {Object.entries(result.specifications || {}).map(
                ([key, value], index) => (
                  <li key={index}>
                    <strong>{key}:</strong> {value}
                  </li>
                )
              )}
            </ul>
            <h4 className="mt-4">
              <strong>Alternative Categories:</strong>
            </h4>
            <ul>
              {result.alternative_categories?.map((category, index) => (
                <li key={index}>{category}</li>
              ))}
            </ul>
            <h4 className="mt-4">
              <strong>Alternative Prices:</strong>
            </h4>
            <ul>
              {Object.entries(result.alternative_prices || {}).map(
                ([key, value], index) => (
                  <li key={index}>
                    <strong>{key}:</strong> {value}
                  </li>
                )
              )}
            </ul>
            <h4 className="mt-4">
              <strong>Similar Items:</strong>
            </h4>
            <ul>
              {result.similar_items?.map((item, index) => (
                <li key={index}>
                  <strong>Title:</strong> {item.title}, <strong>Price:</strong>{" "}
                  {item.price}, <strong>Link:</strong>{" "}
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultsDisplay;
