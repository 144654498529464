import React, { useState, useEffect } from "react";
import axios from "axios";

const CsvUploader = () => {
  const [viewUploads, setViewUploads] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUploads = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `https://devbackend.listornot.com/get-uploads?userId=${userId}`
      );
      setUploads(response.data.uploads);
    } catch (error) {
      console.error("Error fetching uploads:", error.message);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const rows = content.split("\n");

        if (rows.length > 1000) {
          alert(
            "The file exceeds the maximum allowed rows (1000). Please reduce its size."
          );
          setFile(null);
        } else {
          setFile(selectedFile);
        }
      };

      reader.onerror = () => {
        alert("Failed to read the file. Please try again.");
      };

      reader.readAsText(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) return alert("Please select a file to upload.");
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", localStorage.getItem("userId"));

    try {
      const response = await axios.post(
        "https://devbackend.listornot.com/process-csv",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("File uploaded successfully.");
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (viewUploads) {
      fetchUploads();
    }
  }, [viewUploads]);

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6 bg-white p-4 rounded shadow-md">
        <h1 className="text-2xl font-bold text-gray-700">
          {viewUploads ? "View Your Uploads" : "Upload CSV"}
        </h1>
        <button
          onClick={() => setViewUploads(!viewUploads)}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          {viewUploads ? "Switch to Upload" : "Switch to View Uploads"}
        </button>
      </div>

      {!viewUploads ? (
        <div className="space-y-6 bg-white p-6 rounded shadow-md">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Select a CSV file to upload:
          </label>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg cursor-pointer focus:outline-none"
          />
          <button
            onClick={handleUpload}
            className={`w-full py-2 text-white rounded-lg ${
              loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
            } transition`}
            disabled={loading}
          >
            {loading ? "Uploading..." : "Upload CSV"}
          </button>
        </div>
      ) : (
        <div className="bg-white p-6 rounded shadow-md">
          <h2 className="text-lg font-medium text-gray-800 mb-4">
            Your Uploads
          </h2>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-left text-gray-700 font-semibold">
                  File Name
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-gray-700 font-semibold">
                  Download URL
                </th>
              </tr>
            </thead>
            <tbody>
              {uploads.length === 0 ? (
                <tr>
                  <td
                    colSpan="2"
                    className="border border-gray-300 px-4 py-4 text-center text-gray-500"
                  >
                    No uploads found.
                  </td>
                </tr>
              ) : (
                uploads.map((upload, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                  >
                    <td className="border border-gray-300 px-4 py-2 text-gray-700">
                      {upload.fileName}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-blue-500">
                      <a
                        href={upload.downloadUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CsvUploader;
